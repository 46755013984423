*,
::after,
::before {
    box-sizing: border-box;
}

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Roboto+Condensed:wght@400;700&display=swap');

html {
    font-size: 100%;
}

/*16px*/

:root {

    /* Primary */
    --primary-color: #07beb8;
    --primary-15-color: #07beb826;
    --primary-25-color: #07beb840;
    --primary-50-color: #07beb880;
    --primary-75-color: #07beb8bf;

    /* Secondary */
    --secondary-color: #6917d0;
    --secondary-15-color: #6917d026;
    --secondary-25-color: #6917d040;
    --secondary-50-color: #6917d080;
    --secondary-75-color: #6917d0bf;

    /* Tertiary */
    --tertiary-color: #ff1791;
    --tertiary-15-color: #ff179126;
    --tertiary-25-color: #ff179140;
    --tertiary-50-color: #ff179180;
    --tertiary-75-color: #ff1791bf;

    /* Auxiliary */
    --accent-color: #fedc00;
    --success-color: #007a4d;
    --info-color: #023e8a;
    --warning-color: #ff9800;
    --danger-color: #e63946;
    --theme-color: #E2837E;


    /* White */
    --white-color: #fff;
    --white-10-color: rgba(255, 255, 255, 0.1);
    --white-25-color: rgba(255, 255, 255, 0.25);
    --white-50-color: rgba(255, 255, 255, 0.50);
    --white-65-color: rgba(255, 255, 255, 0.65);
    --white-75-color: rgba(255, 255, 255, 0.75);
    --white-85-color: rgba(255, 255, 255, 0.85);

    /* Gray */
    --gray-color: #8f93a5;
    --gray-10-color: rgba(143, 147, 165, 0.1);
    --gray-25-color: rgba(143, 147, 165, 0.25);
    --gray-50-color: rgba(143, 147, 165, 0.50);
    --gray-65-color: rgba(143, 147, 165, 0.65);
    --gray-75-color: rgba(143, 147, 165, 0.75);
    --gray-85-color: rgba(143, 147, 165, 0.85);

    /* Black */
    --black-color: #1a1a20;
    --black-10-color: rgba(26, 26, 32, 0.1);
    --black-25-color: rgba(26, 26, 32, 0.25);
    --black-50-color: rgba(26, 26, 32, 0.50);
    --black-65-color: rgba(26, 26, 32, 0.65);
    --black-75-color: rgba(26, 26, 32, 0.75);
    --black-85-color: rgba(26, 26, 32, 0.85);

    /* box shadow*/
    --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-2: 0 4px 5px -1px rgba(0, 0, 0, 0.4),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);


    --backgroundColor: var(--grey-50);
    --textColor: var(--grey-900);
    --borderRadius: 1rem;


    /* fonts  */
    --headingFont: 'Roboto Condensed', Sans-Serif;
    --bodyFont: 'Lato', Sans-Serif;
    --small-text: 0.875rem;
    --extra-small-text: 0.7em;

    --primary-font: lato, sans-serif;
    --secondary-font: proxima-nova, sans-serif;

    /* rest of the vars */
    --backgroundColor: var(--grey-50);
    --textColor: var(--grey-900);
    --borderRadius: 1rem;
    --letterSpacing: 1px;
    --transition: 0.3s ease-in-out all;
    --max-width: 1120px;
    --fixed-width: 500px;
    --fluid-width: 90vw;
    --breakpoint-lg: 992px;
    --nav-height: 6rem;
}

.theme-color {
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
}

.text-style-1 {
    font-size: 6.2rem;
    font-weight: 800;
}

.text-style-2 {
    font-size: 5.2rem;
    font-weight: 800;
}

.text-style-3 {
    font-size: 4.2rem;
    font-weight: 800;
}

.text-style-4 {
    font-size: 4.2rem;
    font-weight: 100;
}

.text-style-5 {
    font-size: 3.2rem;
    font-weight: 800;
}

.text-style-6 {
    font-size: 3.2rem;
    font-weight: 100;
}

.text-style-7 {
    font-size: 2.2rem;
    font-weight: 700;
}

.text-style-8 {
    font-size: 2.2rem;
    font-weight: 100;
}

.text-style-9 {
    font-size: 1.75rem;
    font-weight: 700;
}

.text-style-10 {
    font-size: 1.75rem;
    font-weight: 100;
}

.text-style-11 {
    font-size: 1.2rem;
    font-weight: 700;
}

.text-style-12 {
    font-size: 1.2rem;
    font-weight: 400;
}

.text-style-13 {
    font-size: 1.1rem;
    font-weight: 100;
}

@media (max-width: 767px) {
    .text-style-1 {
        font-size: 3rem;
    }

    .text-style-2 {
        font-size: 3rem;
    }

    .text-style-3 {
        font-size: 3rem;
    }

    .text-style-4 {
        font-size: 3rem;
    }

    .text-style-5 {
        font-size: 2.2rem;
        font-weight: 700;
    }

    .text-style-6 {
        font-size: 2.2rem;
    }

    .text-style-7 {
        font-size: 1.75rem;
    }

    .text-style-8 {
        font-size: 1.75rem;
    }

    .text-style-9 {
        font-size: 1.2rem;
    }

    .text-style-10 {
        font-size: 1.2rem;
    }

    .text-style-11 {
        font-size: 1rem;
    }

    .text-style-12 {
        font-size: 1rem;
    }
}

body {
    background: var(--backgroundColor);
    font-family: var(--bodyFont);
    font-weight: 400;
    line-height: 1.75;
    color: var(--textColor);
}

p {
    margin-bottom: 1.5rem;
    max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    margin-bottom: 1.38rem;
    font-family: var(--headingFont);
    font-weight: 400;
    line-height: 1.3;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
}

h1 {
    margin-top: 0;
    font-size: 3.052rem;
}

h2 {
    font-size: 2.441rem;
}

h3 {
    font-size: 1.953rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}

small,
.text-small {
    font-size: var(--small-text);
}

a {
    text-decoration: none;
    letter-spacing: var(--letterSpacing);
}

a,
button {
    line-height: 1.15;
}

button:disabled {
    cursor: not-allowed;
}

ul {
    list-style-type: none;
    padding: 0;
}

.img {
    width: 100%;
    display: block;
    object-fit: cover;
}

/* buttons */

.btn {
    cursor: pointer;
    color: var(--white);
    background: var(--primary-500);
    border: transparent;
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.375rem 0.75rem;
    box-shadow: var(--shadow-2);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
}

.btn:hover {
    background: var(--primary-700);
    box-shadow: var(--shadow-3);
}

.btn-hipster {
    color: var(--primary-500);
    background: var(--primary-200);
}

.btn-hipster:hover {
    color: var(--primary-200);
    background: var(--primary-700);
}

.btn-block {
    width: 100%;
}

.btn-hero {
    font-size: 1.25rem;
    padding: 0.5rem 1.25rem;
}

.btn-danger {
    background: var(--red-light);
    color: var(--red-dark);
}

.btn-danger:hover {
    background: var(--red-dark);
    color: var(--white);
}

/* alerts */
.alert {
    padding: 0.375rem 0.75rem;
    margin-bottom: 1rem;
    border-color: transparent;
    border-radius: var(--borderRadius);
    text-align: center;
    letter-spacing: var(--letterSpacing);
    text-transform: capitalize;
}

.alert-danger {
    color: var(--red-dark);
    background: var(--red-light);
}

.alert-success {
    color: var(--green-dark);
    background: var(--green-light);
}

/* form */

.form {
    width: 90vw;
    max-width: var(--fixed-width);
    background: var(--white);
    border-radius: var(--borderRadius);
    box-shadow: var(--shadow-4);
    padding: 2rem 2.5rem;
    margin: 3rem auto;
    transition: var(--transition);
}

.form:hover {
    box-shadow: var(--shadow-2);
}

.form-label {
    display: block;
    font-size: var(--small-text);
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
}

.form-input,
.form-textarea,
.form-select {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: var(--borderRadius);
    background: var(--backgroundColor);
    border: 1px solid var(--grey-200);
}

.form-input,
.form-select,
.btn-block {
    height: 35px;
}

.form-row {
    margin-bottom: 1rem;
}

.form-textarea {
    height: 7rem;
}

::placeholder {
    font-family: inherit;
    color: var(--grey-400);
}

.form-alert {
    color: var(--red-dark);
    letter-spacing: var(--letterSpacing);
    text-transform: capitalize;
}

/* alert */

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid var(--grey-400);
    border-radius: 50%;
    border-top-color: var(--primary-500);
    animation: spinner 2s linear infinite;
}

.loading-center {
    margin: 0 auto;
}

/* title */

.title {
    text-align: center;
}

.title-underline {
    background: var(--primary-500);
    width: 7rem;
    height: 0.25rem;
    margin: 0 auto;
    margin-top: -1rem;
}

.full-page {
    min-height: 100vh;
}

.coffee-info {
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 1rem;
    letter-spacing: var(--letterSpacing);
}

.coffee-info span {
    display: block;
}

.coffee-info a {
    color: var(--primary-500);
}

@media screen and (min-width: 992px) {
    .coffee-info {
        text-align: left;
    }

    .coffee-info span {
        display: inline-block;
        margin-right: 0.5rem;
    }
}

@media (max-width: 767px) {
    .form {
        width: 90vw;
        max-width: var(--fixed-width);
        padding: 0.5rem 1rem;
        margin: 0rem auto;
    }

    .col {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }


}

