.backvid {
  background-color: red;
  width: 100%;
  padding: -10px -10px;
  z-index: -1;
}

.videoContainer {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
}

.videoStyleInner {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
}

.videoStyleBg {
  z-index: -10;
  position: fixed;
  object-fit: cover;
  opacity: 0.8;
}

.bgImage {
  z-index: -5;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  max-height: 100%;
  opacity: 0.3;
}



@media (max-width: 767px) {
  .videoContainer {
    height: 400px;

  }

  .messageBox {
    height: 200px;
  }

}










